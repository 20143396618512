body {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
  padding: 0 20px;
  padding-top: 70px; /* adjust this if necessary */
  font-family: Arial, sans-serif;
  color: #f5f5dc;
  background-color: #111;
}

.container {
  width: 1060px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px; /* adjust this if necessary */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #111;
}

.header-title {
  margin: 0;
  color: #eee;
}

.header-nav {
  flex-grow: 1;
  text-align: center;
}

.header-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}

.header-nav-list li {
  display: inline;
}

.header-nav-list a {
  color: #eee;
  text-decoration: none;
  margin-left: 10px;
}

.header-nav-list a:hover {
  color: #bbb;
}

.subHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.thumbnail {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.image-item {
  width: 200px;
  word-break: break-word;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0px;
}

.gallery-grid-thumbnail {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.dropzone {
  width: 60%;
  height: 200px;
  border: 2px dashed gray;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;  /* center the drop zone horizontally */
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Mobile styles */
@media (max-width: 600px) {
  /* Adjust the header */
  .header {
    flex-direction: column;
    text-align: center;
    height: 100px;
  }

  /* Show fewer images per row */
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Increase font size */
  body {
    font-size: 18px;
  }

  /* Increase button size */
  button {
    padding: 10px 20px;
    font-size: 18px;
  }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 900px) {
  .image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Desktop styles */
@media (min-width: 901px) {
  .image-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
